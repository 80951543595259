import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useUID } from 'react-uid';
import { twJoin, twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

import Text from '../Text';

const switchTrackSizes = {
  size1: 'min-w-10 min-h-5 before:size-4 peer-checked:before:translate-x-5',
  size2: 'min-w-8 min-h-4 before:size-3 peer-checked:before:translate-x-4',
};

const switchThumbSizes = {
  size1: 'w-10',
  size2: 'w-8',
};

const switchLabelSizes = {
  size1: 'text-sm md:text-base',
  size2: 'text-sm',
};

const Switch = forwardRef(
  (
    {
      checked = false,
      className,
      defaultChecked = false,
      disabled = false,
      id: customId,
      label,
      name,
      size = 'size1',
      onClick = noop,
      onChange = noop,
      ...rest
    },
    ref
  ) => {
    const uniqueId = `switch_${useUID()}`;
    const id = customId || uniqueId;

    return (
      <div
        className={twMerge('relative inline-flex items-center', className)}
        {...rest}
      >
        <input
          ref={ref}
          checked={defaultChecked || checked}
          className={twJoin(
            'peer absolute left-0 top-0 z-2 h-full opacity-0 outline-0 checked:border-primary-300 checked:bg-primary-300',
            switchThumbSizes[size],
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
          disabled={disabled}
          id={id}
          name={name}
          size={size}
          type="checkbox"
          suppressHydrationWarning
          onChange={onChange}
          /**
           * is used to suppress errors related of inconsistencies between
           * client-side and server-side of ids generated by 'react-uid'
           */
          onClick={onClick}
        />
        <div
          className={twJoin(
            'relative inline-flex rounded-full border transition-all duration-150 ease-in focus:shadow-[0_0_0_0.125rem_theme(colors.primary.200)]',
            'before:absolute before:left-px before:top-px before:block before:rounded-full before:shadow before:transition-all before:duration-150 before:ease-in before:content-empty',
            disabled
              ? 'border-neutral-high-300 bg-neutral-high-300 shadow-none before:bg-neutral-high-100 peer-checked:border-primary-200 peer-checked:bg-primary-200 dark:border-neutral-low-300 dark:bg-neutral-low-300 dark:before:bg-neutral-low-100 dark:peer-checked:border-primary-200 dark:peer-checked:bg-primary-200 dark:peer-checked:before:bg-neutral-high-300'
              : 'border-neutral-high-500 bg-neutral-high-500 before:bg-neutral-high-100 peer-checked:border-primary-300 peer-checked:bg-primary-300',
            'peer-focus-within:shadow-[0_0_0_0.125rem_theme(colors.primary.200)] peer-focus:shadow-[0_0_0_0.125rem_theme(colors.primary.200)]',
            switchTrackSizes[size]
          )}
          aria-hidden
        />
        {label && (
          <Text
            as="label"
            className={twJoin(
              'ml-2 select-none',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              switchLabelSizes[size]
            )}
            htmlFor={id}
          >
            {label}
          </Text>
        )}
      </div>
    );
  }
);

Switch.displayName = 'Switch';

Switch.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   *  Renders a checked switch
   */
  checked: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Default value for uncontrolled component
   */
  defaultChecked: PropTypes.bool,
  /**
   *  Renders a disabled checkbox
   */
  disabled: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Custom identifier for the `Switch` input. If this is not provided,
   * we will add a unique id with the prefix `switch_`
   */
  id: PropTypes.string,
  /**
   * Renders a label to the right
   */
  label: PropTypes.string,
  /**
   * Renders a text with a determined size
   */
  size: PropTypes.oneOf(['size1', 'size2']),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on change
   */
  onChange: PropTypes.func,
};

export default Switch;
